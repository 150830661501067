import config from 'config'
import querySerializer from 'query-string'
import { pathToAction } from 'redux-first-router'
import { createBasicSelector } from 'util/redux'
import { createSelector } from 'reselect'
import * as deviceTypes from 'constants/device_types'
import * as pages from 'constants/pages'
import * as TicketListTypes from 'constants/conversation_list_types'

import { isEmpty, emptyObj, isObject } from 'util/objects'

import { downcase } from 'util/strings'
import { generateAutoLoginUrl } from 'util/auth'
import { emptyArr } from 'util/arrays'
import selectAccountCreatedAtEpochMs from 'ducks/billing/selectors/selectAccountCreatedAtEpochMs'
import { selectAccount } from 'ducks/accounts/selectors/selectAccount'
import { selectAccountSubdomain } from 'ducks/accounts/selectors/selectAccountSubdomain'

import { selectApplication } from './selectApplication'
import { selectIsRightSidebarCollapsed } from './selectIsRightSidebarCollapsed'
import {
  selectAccountPreferences,
  selectAccountCanAccess20Beta,
  selectAccountPrefersUnsafeCustomProfile,
  selectAccountPrefersHistorical,
  selectAccountPreferredReportsAverage,
  selectAccountCanSeePinnedSearches,
  selectAccountPrefersScopedContactsSearch,
  selectShowExperimentalRealtimeWarnings,
  selectShowExperimentalLabelUi,
  selectShowSelfHelpCancellation,
  selectCanMentionAnyAgent,
  selectShouldPreloadTickets,
  selectAccountPreferenceCustomProfileAppInstalled,
  selectAccountPreferenceReportingPermission,
} from './selectAccountPreferences'

const selectGroups = state => state.groups || emptyArr

export {
  selectApplication,
  selectAccount,
  selectAccountSubdomain,
  selectIsRightSidebarCollapsed,
  selectAccountPreferences,
  selectAccountCanAccess20Beta,
  selectAccountPrefersUnsafeCustomProfile,
  selectAccountPrefersHistorical,
  selectAccountPreferredReportsAverage,
  selectAccountCanSeePinnedSearches,
  selectAccountPrefersScopedContactsSearch,
  selectShowExperimentalRealtimeWarnings,
  selectShowExperimentalLabelUi,
  selectShowSelfHelpCancellation,
  selectCanMentionAnyAgent,
  selectShouldPreloadTickets,
  selectAccountPreferenceCustomProfileAppInstalled,
  selectGroups,
  selectAccountPreferenceReportingPermission,
}

export const selectKeysPressed = state => state.app.keysPressed

export const selectDeviceType = state => state.app.deviceType

export const selectDeviceTypeIsDesktop = createSelector(
  selectDeviceType,
  appType => appType === deviceTypes.DESKTOP
)

export const selectNavigationVisible = state =>
  state.app.navigationVisible || false

export const selectIsMailboxSwitcherVisible = state =>
  state.app.isMailboxSwitcherVisible || false

export const selectShouldUseNotesV2 = createSelector(
  selectAccountPreferences,
  prefs => {
    if (localStorage.forceNotesVersion === 'v2') return true
    if (localStorage.forceNotesVersion === 'v1') return false
    if (prefs.fullstack) return true
    if (window.location.host.match('groovehq.docker')) return true
    if (window.location.host.match('bu-drafts-v2-notes.alpha')) return true
    if (config.isDevelopment) return true
    return !!prefs.should_use_notes_v2
  }
)

export const selectAccountName = createSelector(
  selectAccount,
  account => account.name
)

export const selectAccountIsOnboardingCompleted = createSelector(
  selectAccount,
  account => account.is_onboarding_completed
)

export const selectPrimaryKnowledgeBaseId = createSelector(
  selectAccount,
  account => account.primary_knowledge_base_id
)

export const isBoostrappedSelector = state => !!state.app.bootstrapped

export const selectIsInboxBootstrapped = state => !!state.app.bootstrappedInbox

export const coreFetchingStatusesSelector = state => state.app.fetchingStatuses
export const extractedFetchingStatusesSelector = state =>
  state.fetching.fetchingStatuses

export const fetchingStatusesSelector = createSelector(
  coreFetchingStatusesSelector,
  extractedFetchingStatusesSelector,
  (core, extracted) => ({ ...core, ...extracted })
)

export const selectIsRefreshingToken = createBasicSelector(
  fetchingStatusesSelector,
  statuses => statuses.tokenRefresh === true
)

export const selectIsLoadingTicket = createSelector(
  fetchingStatusesSelector,
  statuses => statuses.fetchTicket
)

export const selectIsLoadingTicketActions = createSelector(
  fetchingStatusesSelector,
  statuses => statuses.fetchTicketActions
)

export const selectIsSearchingUsers = createSelector(
  fetchingStatusesSelector,
  statuses => statuses.searchUsers
)

export const selectIsLoadingLabels = createSelector(
  fetchingStatusesSelector,
  statuses => statuses.labels
)

export const selectIsFetchingCannedReplyForInsert = createSelector(
  fetchingStatusesSelector,
  statuses => statuses.fetchCannedReplyForInsert
)

export const selectIsFetchingLabelSearchResults = createSelector(
  selectIsLoadingLabels,
  status => status
)

export const selectIsLoadingTopLabels = createSelector(
  fetchingStatusesSelector,
  statuses => statuses.fetchTopLabelsForSelection
)

export const oauthTokenSelector = state => state.app.token

export const selectIsStandalone = state => state.app.isStandalone

export const selectIsAuthenticating = createSelector(
  fetchingStatusesSelector,
  statuses => !!statuses.authenticating
)

export const isAuthenticatedSelector = state => !!state.app.authenticated

export const isRealtimeSubscribedSelector = state =>
  !!state.app.realtimeSubscribed

export const hasRealtimeConnectedOnceSelector = state =>
  !!state.app.realtimeConnectedOnce

export const selectIsUpdateAvailable = state => state.app.updateAvailable

export const selectGroupsById = createSelector(selectGroups, groups => {
  return groups.reduce((hash, group) => {
    // eslint-disable-next-line no-param-reassign
    hash[group.id] = group
    return hash
  }, {})
})

export const selectGroup = (state, groupId) => {
  const byId = selectGroupsById(state)
  return byId[groupId]
}

export const selectGroupsByName = createSelector(selectGroups, groups =>
  groups.reduce((hash, group) => {
    // eslint-disable-next-line no-param-reassign
    hash[downcase(group.name)] = group
    return hash
  }, {})
)

const getGroupAgentIds = group => {
  if (!group || !group.agents) return []
  return group.agents.map(agent => agent.id)
}

export const isAgentInGroup = (group, id) =>
  getGroupAgentIds(group).includes(id)

const selectCurrentStoredTicketList = state => state.app.currentTicketList

export const selectLatestMailboxContext = state =>
  state.app.latestMailboxContext

export const selectMailboxFolderMap = state => state.app.mailboxFolderMap

export const selectMailboxIds = state => state.app.mailboxIds

export const selectCurrentTicketList = createSelector(
  selectCurrentStoredTicketList,
  stored => {
    if (stored && stored.id) return stored
    return emptyObj
  }
)

export const selectCurrentTicketListType = createSelector(
  selectCurrentTicketList,
  listObj => listObj.type
)

export const selectCurrentMailbox = createSelector(
  selectCurrentTicketList,
  listObj => listObj.mailbox
)

export const selectCurrentFolderId = createSelector(
  selectCurrentTicketList,
  listObj => {
    if (isEmpty(listObj)) return null
    if (listObj.type !== TicketListTypes.FOLDER) return null
    return listObj.id
  }
)

export const selectCurrentSearchQueryString = createSelector(
  selectCurrentTicketList,
  listObj => {
    if (isEmpty(listObj)) return null
    if (listObj.type !== TicketListTypes.SEARCH) return null
    return listObj.id
  }
)

export const selectCurrentSortOrder = createSelector(
  selectCurrentTicketList,
  listObj => {
    if (isEmpty(listObj)) return null
    // dont care about type. FOLDER is deprecated anyway.
    return listObj.sortOrder
  }
)

export const selectCurrentFolderPath = createSelector(
  selectCurrentFolderId,
  currentFolderId => {
    return currentFolderId ? `/folders/${currentFolderId}` : '/'
  }
)

export const selectCurrentTicketListRouteAction = createSelector(
  selectCurrentSearchQueryString,
  selectCurrentSortOrder,
  (term, sortOrder) => {
    if (!term) return null
    return {
      type: pages.SEARCH_PAGE,
      payload: { term },
      meta: {
        query: {
          sort: sortOrder,
        },
      },
    }
  }
)

export const selectBackButtonRouteAction = createSelector(
  state => state.app.prevLocationBeforeTicketIdSearch,
  selectCurrentTicketListRouteAction,
  (prevLocationBeforeTicketIdSearch, currentTicketListRouteAction) => {
    return (
      prevLocationBeforeTicketIdSearch || currentTicketListRouteAction || '/'
    )
  }
)

export const selectPreviousMailboxId = state => state.app.previousMailbox

export const selectReturnTo = state => {
  const { location: { routesMap } = {}, app: { returnTo } = {} } = state

  if (!returnTo) return {}
  if (isObject(returnTo)) return returnTo

  let routePath = null

  if (returnTo.startsWith('http')) {
    const { pathname, search } = new URL(returnTo)
    routePath = `${pathname}${search}`
  } else {
    routePath = returnTo
  }

  return pathToAction(routePath, routesMap, querySerializer)
}

export const selectReturnPath = createSelector(
  selectReturnTo,
  returnTo => returnTo.path || '/'
)

export const selectHasReturnToPath = createSelector(
  selectReturnPath,
  path => path && path !== '/'
)

export const selectIsOnReportsMainPage = state =>
  state.page.componentName === 'ReportsMainPage'

export const selectPreviousScrollY = state => {
  return state.app.previousScrollY || 0
}

export const selectIsTicketsCurtainVisible = state =>
  state.app.ticketsCurtainVisible

export const selectSnackbar = state => state.app.snackbar || {}

export const selectSnackbarIsOpen = createSelector(
  selectSnackbar,
  snackbar => snackbar.open || false
)

export const selectSnackbarProps = createSelector(
  selectSnackbar,
  snackbar => snackbar.props || {}
)

export const selectIsEditingTicketTitle = state => state.app.editingTicketTitle

export const selectEditingTicketTitleDraft = state =>
  state.app.editingTicketTitleDraft

export const selectEditorToolbarMessages = state =>
  state.app.editorToolbarMessages || []

export const selectNextEditorToolbarMessage = createSelector(
  selectEditorToolbarMessages,
  messages => messages[0] || { messageType: 'DEFAULT' }
)

export const selectIsJustClosedKeyboard = state => state.app.justClosedKeyboard

export const selectCurrentOpenNavSections = state => {
  return state.app.currentOpenNavSection
}

export const selectLatestLoginError = state => state.app.latestLoginError
export const selectLatestResetPasswordError = state =>
  state.app.latestResetPasswordError
export const selectLatestAccountRecoveryError = state =>
  state.app.latestAccountRecoveryError

export const selectIsOtpChallenged = state => !!state.app.otpChallenged
export const selectOtpIdentifier = state => state.app.otpIdentifier

// Folder context is a pair of current folder and current search
// If this changed, it means that we switched using the left
// sidebar
export const selectCurrentFolderContext = createSelector(
  selectCurrentFolderId,
  selectCurrentSearchQueryString,
  (folderId, queryString) => {
    return [folderId, queryString].join(' | ')
  }
)

export const selectIsTicketListScrolledToTop = state =>
  state.app.isTicketListScrolledToTop

export const selectTicketListBeforeSearch = state =>
  state.app.ticketListBeforeSearch

export const selectLastViewedTicketId = state => state.app.viewTicketLastId

export const selectLastRepliedTicketId = state => state.app.replyToTicketLastId

export const selectLastTypedTicketId = state => state.app.typeOnTicketLastId

export const selectLastListPage = state => state.app.lastListPage

export const selectMailboxSearch = state => state.app.mailboxSearchInput

export const selectIsMobileSupported = state =>
  state.page.subapp === 'onboarding'

export const selectIsRefreshingSearches = state =>
  state.app.isRefreshingSearches

// When we redirect the page to login page in routeOptions.js with meta,
// we keep showing the current url for return after login
export const selectIsOnLoginPage = state =>
  state.location.type === pages.LOGIN_PAGE ||
  state.page.componentName === 'LoginPage'

export const selectIsOnLoginMultipleAccountsPage = state =>
  state.location.type === pages.LOGIN_MULTIPLE_ACCOUNTS_PAGE

export const selectIsOnLoginAgentMissingPage = state =>
  state.location.type === pages.LOGIN_AGENT_MISSING_PAGE

export const selectIsOnForgotPasswordPage = state =>
  state.location.type === pages.FORGOT_PASSWORD_PAGE

export const selectIsOnForgotSubdomainPage = state =>
  state.location.type === pages.FORGOT_SUBDOMAIN_PAGE

export const selectIsOnChangePasswordPage = state =>
  state.location.type === pages.CHANGE_PASSWORD_PAGE

export const selectIsOnOtpChallengePage = state =>
  state.location.type === pages.OTP_CHALLENGE_PAGE

export const selectIsOnOauthErrorPage = state =>
  state.location.type === pages.OAUTH_ERROR_PAGE

export const selectIsOnAIAgentPage = state =>
  state.location.type === pages.AI_AGENT_PAGE

export const selectLastTicketListScrollerScrollTop = state => {
  const { lastTicketListScrollerScrollState } = selectApplication(state)
  return (
    (lastTicketListScrollerScrollState &&
      lastTicketListScrollerScrollState.scrollTop) ||
    null
  )
}

export const selectAvailableAccounts = state => {
  return state.app.availableAccounts || emptyArr
}

export const selectAuthProvider = state => {
  return state.app.auth.provider
}

export const selectIsCannedReplyDropdownVisible = state =>
  state.app.cannedReplyDropdown.isVisible
export const selectCannedReplyDropdownSource = state =>
  state.app.cannedReplyDropdown.source
export const selectCannedReplyDropdownCursorBookmark = state =>
  state.app.cannedReplyDropdown.cursorBookmark

const selectSubdomainForAutoLoginUrl = createSelector(
  selectAccount,
  account => {
    return config.isDevelopment || !account ? 'mobile' : account.subdomain
  }
)

export const selectAutoLoginUrl = createSelector(
  selectSubdomainForAutoLoginUrl,
  oauthTokenSelector,
  (subdomain, token) => generateAutoLoginUrl(subdomain, token)
)

export const selectAccountUrl = createSelector(
  selectSubdomainForAutoLoginUrl,
  subdomain => `https://${subdomain}.${config.appDomain}/`
)

export const selectIsPrintModeOn = createSelector(
  selectApplication,
  app => app.printMode || false
)

// any account created after this launch date should not have any missing data
// by missing data we mean stuff like created_by columns that were recently added to tags model for example
export const selectShowNewSettingsMissingDataNotice = createSelector(
  isBoostrappedSelector,
  selectAccountCreatedAtEpochMs,
  (isBootStrapped, accountCreatedAtEpochMs) => {
    if (!isBootStrapped || accountCreatedAtEpochMs === null) return false

    // just made the launch date of overview  drawers March 1 2022.
    // we added the new columns and started filling data late 2021 anyway
    return accountCreatedAtEpochMs <= new Date(2022, 2, 1)
  }
)

export const selectCompanyProfile = state =>
  selectApplication(state).companyProfile || emptyObj

export const selectAccountUsageOnboarding = createSelector(
  selectAccount,
  account => {
    return account.preferences?.usage_onboarding || emptyObj
  }
)

export const selectIsSlackInstalled = createSelector(
  selectAccountUsageOnboarding,
  usageOnboarding => {
    return usageOnboarding.slack_installed
  }
)
export const selectIsShopifyV1Installed = createSelector(
  selectAccountUsageOnboarding,
  usageOnboarding => usageOnboarding.shopify_connected
)

export const selectIsShopifyV2Installed = createSelector(
  selectAccountUsageOnboarding,
  usageOnboarding => usageOnboarding.shopify_v2_connected
)
