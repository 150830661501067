/* eslint-disable no-multi-assign */ /* this is ok in reducers */

// Our primary (RFR) reducer that replaces the need for a [React Router-esque]
// <Route /> component. It maps the current page state (e.g. HOME) to a given
// component. In doing this it also maps the location state to the arguments
// that the component expects. (Note: this follows the RFR code example. Is
// arguable this state doesnt need to be in redux - it could be derived in a
// selector. This same path state is available in getState().location.payload)
import { NOT_FOUND } from 'redux-first-router'
import * as pages from 'constants/pages'
import * as dt from 'constants/draft_types'
import duckPageReducer from 'ducks/pageReducer'
import { handlers as settingsHandlers } from 'subapps/settings/routes'
import { handlers as onboardingHandlers } from 'subapps/onboarding/routes'
import { pageReducers as subAppPageReducers } from 'subapps'
import { isMobile } from 'util/media_query'
import { isBlank } from 'util/strings'
import { emptyArr } from 'util/arrays'

// Kevin Rademan (2020-06-08)
// Fixing a circular dependency
import { REPORTS_MAIN_PAGE } from 'ducks/reports/pages'

const defaultState = { componentName: 'MainPage' }

const components = {
  ...settingsHandlers,
  ...onboardingHandlers,
  ...subAppPageReducers,
}

components[pages.MAIN_PAGE] = () => defaultState

components[pages.MAILBOX_DEFAULT_PAGE] = () => ({
  componentName: 'FolderPage',
})

components[pages.MAILBOX_PAGE] = ({ mailboxId }) => ({
  componentName: 'FolderPage',
  props: { mailboxId },
})

components[pages.MAILBOX_FOLDER_PAGE] = ({ mailboxId, folderId }) => ({
  componentName: 'FolderPage',
  props: { mailboxId, folderId },
})

components[pages.FOLDERS_PAGE] = () => ({
  componentName: 'FolderPage',
})

components[pages.FOLDER_PAGE] = ({ folderId }) => ({
  componentName: 'FolderPage',
  props: { folderId },
})

components[REPORTS_MAIN_PAGE] = () => ({
  componentName: 'ReportsMainPage',
})

components[pages.LOGIN_PAGE] = () => ({ componentName: 'LoginPage' })

components[pages.LOGOUT_PAGE] = () => ({ componentName: 'LogoutPage' })

components[pages.LOGIN_MULTIPLE_ACCOUNTS_PAGE] = () => ({
  componentName: 'LoginMultipleAccountsPage',
})

components[pages.LOGIN_SELECTOR] = () => ({
  componentName: 'LoginSelectorPage',
})

components[pages.LOGIN_AGENT_MISSING_PAGE] = () => ({
  componentName: 'LoginAgentMissingPage',
})

components[pages.OTP_CHALLENGE_PAGE] = () => ({
  componentName: 'OtpChallengePage',
})

components[pages.FORGOT_SUBDOMAIN_PAGE] = () => ({
  componentName: 'ForgotSubdomainPage',
})

components[pages.FORGOT_PASSWORD_PAGE] = () => ({
  componentName: 'ForgotPasswordPage',
})

components[pages.CHANGE_PASSWORD_PAGE] = () => ({
  componentName: 'ChangePasswordPage',
})

components[pages.OAUTH_ERROR_PAGE] = () => ({
  componentName: 'OauthErrorPage',
})

components[pages.AI_AGENT_PAGE] = () => ({
  componentName: 'AiAgentPage',
})

components[pages.NEW_CONVERSATION_PAGE] = (
  _,
  { query: { show = '' } = {} }
) => ({
  componentName: 'NewConversationPage',
  props: {
    isNewEmail: true,
    visibleFields: isBlank(show) ? emptyArr : show.split(','),
  },
})

components[pages.LOG_CONVERSATION_PAGE] = (
  _,
  { query: { show = '' } = {} }
) => ({
  componentName: 'LogConversationPage',
  props: {
    draftType: dt.NOTE,
    isNewConversation: true,
    visibleFields: isBlank(show) ? emptyArr : show.split(','),
  },
})

components[NOT_FOUND] = () => ({ componentName: 'NotFoundPage' })

components[pages.RAW_EMAIL_PAGE] = ({ id }) => ({
  componentName: 'RawEmailPage',
  props: { commentId: id },
})

components[pages.SEARCH_PAGE] = ({ term } = {}) => {
  return {
    componentName: 'SearchPage',
    props: {
      term,
    },
  }
}

const mapReplyPage = draftType => (
  { id, changesetId, viaKeyboard },
  { query: { expand, show = '' } = {} }
) => ({
  componentName: 'TicketPage',
  props: {
    changesetId,
    draftType,
    isReplyHeaderExpanded: expand,
    isReplying: true, // deprecated. Use draftType
    isForwarding: draftType === dt.FORWARD,
    ticketId: id,
    viaKeyboard,
    visibleFields: isBlank(show) ? emptyArr : show.split(','),
  },
})

components[pages.TICKET_PAGE] = (payload, meta) => {
  // on desktop, the ticket page == the reply page
  if (!isMobile()) return mapReplyPage(dt.REPLY_ALL)(payload, meta)

  return {
    componentName: 'TicketPage',
    props: { ticketId: payload.id },
  }
}

components[pages.TICKET_REPLY_PAGE] = components[
  pages.TICKET_REPLY_CHANGESET_PAGE
] = mapReplyPage(dt.REPLY_ALL)

components[pages.TICKET_REPLY_DIRECT_PAGE] = components[
  pages.TICKET_REPLY_DIRECT_CHANGESET_PAGE
] = mapReplyPage(dt.REPLY_DIRECT)

components[pages.TICKET_FORWARD_PAGE] = mapReplyPage(dt.FORWARD)

components[pages.TICKET_FORWARD_CHANGESET_PAGE] = mapReplyPage(dt.FORWARD)

components[pages.TICKET_COMMENT_PAGE] = ({ id, changesetId }) => ({
  componentName: 'TicketPage',
  props: { ticketId: id, changesetId, isAddingNote: true, draftType: dt.NOTE },
})

export function registerReducer(type, func) {
  components[type] = func
}

export default (state = defaultState, action = {}) => {
  if (!components[action.type]) return state
  return (
    components[action.type](action.payload, action.meta) ||
    duckPageReducer(state, action)
  )
}
